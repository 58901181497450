export enum PartnerType {
  AGENT = 'AGENT',
  PARTNER = 'PARTNER',
}
export enum PartnerState {
  ACTIVE = 'ACTIVE',
  APPROVED = 'APPROVED',
  BLOCKED = 'BLOCKED',
  CLOSED = 'CLOSED',
  INACTIVE = 'INACTIVE',
}

export const getPartnerStateLabel = (state: PartnerState): string => {
  switch (state) {
    case PartnerState.ACTIVE:
      return $localize`En attente`;
    case PartnerState.APPROVED:
      return $localize`Approuvé`;
    case PartnerState.BLOCKED:
      return $localize`Bloqué`;
    case PartnerState.CLOSED:
      return $localize`Fermé`;
    case PartnerState.INACTIVE:
      return $localize`Inactif`;
  }
};

export const getPartnerStateBadgeClass = (state: PartnerState): string => {
  switch (state) {
    case PartnerState.ACTIVE:
      return 'pending';
    case PartnerState.APPROVED:
      return 'success';
    case PartnerState.BLOCKED:
      return 'danger';
    case PartnerState.CLOSED:
      return 'danger';
    case PartnerState.INACTIVE:
      return '';
  }
};

export const getAgentStateBadgeClass = (state: PartnerState): string => {
  if (state === PartnerState.ACTIVE) return 'success';
  return getPartnerStateBadgeClass(state);
};

export const getAgentStateLabel = (state: PartnerState): string => {
  if (state === PartnerState.ACTIVE) return $localize`Actif`;
  return getPartnerStateLabel(state);
};