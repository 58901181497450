import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import * as Sentry from '@sentry/angular-ivy';
import { provideUserIdleConfig } from 'angular-user-idle';
import { BnNgIdleService } from 'bn-ng-idle';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { FileDragDropDirective } from './_directives/file-drag-drop.directive';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarComponent } from './common/partner-dashboard/sidebar/sidebar.component';
import { FooterComponent as HomeFooterComponent } from './common/partner-front/footer/footer.component';
import { Globals } from './globals';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';
import { MarketingComponent } from './marketing/marketing.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { SelectAllCheckboxComponent } from './select-all-checkbox/select-all-checkbox.component';
import { SharedModule } from './shared/shared.module';
import { SupportComponent } from './support/support.component';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { GlobalErrorHandler } from './_handlers/global-error.handler';
registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    HomeFooterComponent,
    DashboardLayoutComponent,
    HomeLayoutComponent,
    SidebarComponent,
    PagenotfoundComponent,
    SelectAllCheckboxComponent,
    FileDragDropDirective,
    PrivacyComponent,
    MarketingComponent,
    SupportComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot({}),
    NgbModule,
    NgScrollbarModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: false,
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    provideUserIdleConfig({ idle: 60, timeout: 60, ping: 30 }),
    BnNgIdleService,
    Globals,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'fr-CH'},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
