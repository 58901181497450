import { Component } from '@angular/core';
import { AuthService } from './_services/auth.service';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent {
  title = 'partners';

  constructor(private authService: AuthService) {
    authService.handleTokenInit();
  }
}
