import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  headers: HttpHeaders;
  _baseURL: any;
  deviceId: any;
  headersLogin: any;
  deviceInfo: any;

  logoutTimeoutId!: ReturnType<typeof setTimeout>;

  constructor(
    private httpClient: HttpClient,
    private deviceService: DeviceDetectorService,
    private router: Router
  ) {
    this.deviceId = Date.now();
    this.deviceInfo = this.deviceService.getDeviceInfo();
    let devicedetail: any = {
      platform: 'Website',
      browser: this.deviceInfo.browser,
      browserVersion: this.deviceInfo.browser_version,
      osVersion: this.deviceInfo.os_version,
      deviceId: this.deviceInfo.device + this.deviceInfo.deviceType,
      appVersion: '',
      ipAddress: '',
      macAddress: '',
    };
    this.headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('User_Agent', JSON.stringify(devicedetail));
    this._baseURL = environment.apiUrl;
  }

  getLogin(obj: any): Observable<any> {
    this.headersLogin = new HttpHeaders()
      .set('content-type', 'application/json')
      .set(
        'User_Agent',
        '{"platform" : "website","browser" : "","browserVersion" : "","osVersion" : "", "deviceId" : ' +
          this.deviceId +
          ',"appVersion" : "","ipAddress" : "","macAddress" : ""}'
      );
    return this.httpClient.post(
      this._baseURL + 'api/ims/v1/partner/signin',
      obj,
      { headers: this.headersLogin }
    );
  }
  signUp(obj: any): Observable<any> {
    return this.httpClient.post(
      this._baseURL + 'api/ims/v1/partner/signup',
      obj,
      { headers: this.headers }
    );
  }
  verifyOtp(obj: any): Observable<any> {
    return this.httpClient.post(
      this._baseURL + 'api/ims/v1/partner/perform/action',
      obj,
      { headers: this.headers }
    );
  }
  _forgotPassword(obj: any): Observable<any> {
    return this.httpClient.put(
      this._baseURL + 'api/ims/v1/partner/forgot/password',
      obj,
      { headers: this.headers }
    );
  }
  _forgotPasswordVerification(obj: any): Observable<any> {
    return this.httpClient.post(
      this._baseURL + 'api/ims/v1/partner/verify/forgot/password',
      obj,
      { headers: this.headers }
    );
  }
  _resendOtp(obj: any): Observable<any> {
    let device: any = Date.now() + 'lty';
    let header1 = new HttpHeaders()
      .set('accept', 'application/json')
      .set('data-hash', 'testww1')
      .set('device-id', device)
      .set('Content-Type', 'application/json');
    return this.httpClient.put(
      this._baseURL + 'api/communicator/v1/otp/resend',
      obj,
      { headers: header1 }
    );
  }
  _logout(obj: any): Observable<any> {
    return this.httpClient.post(
      this._baseURL + 'api/ims/v1/partner/signout',
      obj,
      { headers: this.headers.append('Access-Token', obj.token) }
    );
  }

  isUserLoggedIn(): boolean {
    return !!localStorage.getItem('partner_user');
  }

  checkTokenServerSide() {
    if (this.isUserLoggedIn()) {
      const token = JSON.parse(localStorage.getItem('partner_user')!).token;
      this.httpClient
        .post(
          this._baseURL + 'api/ims/v1/partner/details/token',
          { token },
          { headers: { 'Access-Token': token } }
        )
        .subscribe({
          next: () => {},
          error: () => {
            localStorage.removeItem('partner_user');
            localStorage.removeItem('loginTime');
            window.location.reload();
          },
        });
    }
  }

  handleTokenInit() {
    if (this.isUserLoggedIn()) {
      const expirationTime = JSON.parse(
        localStorage.getItem('partner_user')!
      ).expirationTime!;
      if (
        expirationTime &&
        !Number.isNaN(expirationTime) &&
        Number(expirationTime) > 0
      ) {
        if (Number(expirationTime) < new Date().getTime()) {
          this.logout();
        } else {
          if (this.logoutTimeoutId) {
            clearTimeout(this.logoutTimeoutId);
          }
          this.logoutTimeoutId = setTimeout(
            () => {
              this.logout();
            },
            Number(expirationTime) - new Date().getTime()
          );
        }
      }
    }
  }

  logout() {
    localStorage.removeItem('loginTime');
    localStorage.removeItem('partner_user');
    if (this.logoutTimeoutId) {
      clearTimeout(this.logoutTimeoutId);
    }
    this.router.navigate(['/login']);
  }
}
