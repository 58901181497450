import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RoleService } from '../_services/role.service';

export const AuthGuardGuard = () => {
  const router = inject(Router);
  checkUserLogin(router);
};

function checkUserLogin(route: Router) {
  let auth = JSON.parse(localStorage.getItem('partner_user') || '{}');
  if (!auth) {
    route.navigate(['/login']);
    return false;
  }

  if (!auth.token) {
    route.navigate(['/login']);
    return false;
  }
  if (auth.userType != 'PARTNER' && auth.userType != 'AGENT') {
    route.navigate(['/login']);
    return false;
  }
  return true;
}
export const isUseActiveGuard = () => {
  const router = inject(Router);
  const toasterService = inject(ToastrService);
  isUserActive(router, toasterService);
};

function isUserActive(route: Router, toasterService: ToastrService) {
  let auth = JSON.parse(localStorage.getItem('partner_user') || '{}');
  if (auth.partnerState == 'ACTIVE' && auth.userType === 'PARTNER') {
    toasterService.error(
      $localize`Ton compte est en attente d'approbation`,
      ''
    );
    route.navigate(['/dashboard']);
    return false;
  }
  return true;
}
export const checkUseRoleGuard = (ActivatedRouteSnapshot: any) => {
  const router = inject(Router);
  const roleService = inject(RoleService);
  const toasterService = inject(ToastrService);
  checkUseRole(router, ActivatedRouteSnapshot, toasterService, roleService);
};
function checkUseRole(
  router: Router,
  activatedRouteSnapshot: ActivatedRouteSnapshot,
  toasterService: ToastrService,
  roleService: RoleService
) {
  let auth = JSON.parse(localStorage.getItem('partner_user') ?? '{}');
  if (auth.userType === 'USER' || auth.userType === 'PARTNER') {
    return;
  }
  let obj = {
    userId: auth.partnerId,
  };
  roleService._getUserRoleByUserId(obj).subscribe((data: any) => {
    let userRole: any = data.data.useCasesList;
    let checkRole = userRole.find(
      (element: any) => element.useCaseId == activatedRouteSnapshot.data['useCaseId']
    );
    let msg = '';
    if (checkRole) {
      let checkPerMissions = checkRole.permissionIds.find(
        (element: any) => element == activatedRouteSnapshot.data['pid']
      );
      if (activatedRouteSnapshot.data['pid'] == 1) {
        msg = $localize`Vous n'avez pas l'accès en lecture à cette ressource`;
      }
      if (activatedRouteSnapshot.data['pid'] == 2) {
        msg = $localize`Vous n'avez pas l'accès en écriture à cette ressource`;
      }
      if (activatedRouteSnapshot.data['pid'] == 3) {
        msg = $localize`Vous n'avez pas le droit de supprimer cette ressource`;
      }
      if (!checkPerMissions) {
        toasterService.error(msg, '');
        router.navigate(['/dashboard']);
      } else {
        return;
      }
    } else {
      router.navigate(['/dashboard']);
    }
  });
}
