<div class="mobile-navbar">
  <div class="sidebar-logo-container">
    <img class="sidebar-logo" src="assets/images/logo_white.png" width="36px" height="auto" alt="" />
    <span i18n>Partners</span>
  </div>
<app-menu-icon color="white" (click)="navbarOpen = true" height="24px" />
</div>
<div class="navbar-container" [ngClass]="{'open': navbarOpen}">

<aside class="aside-navbar" *ngIf="user">
  <div class="d-flex justify-content-between align-items-center mb-24">
    <div class="sidebar-logo-container">
      <img class="sidebar-logo" src="assets/images/logo_white.png" width="36px" height="auto" alt="" />
      <span i18n>Partners</span>
    </div>
    <app-cross-icon *ngIf="navbarOpen" height="24px" (click)="navbarOpen = false;" color="white" />
  </div>
  <ul class="nav-list">
    <li class="nav-item">
      <a [routerLink]="['/dashboard']" [routerLinkActive]="['nav-active']"><span><i
            class="bi bi-grid-fill"></i></span><ng-container i18n>Dashboard</ng-container></a>
    </li>
    <li class="nav-item" *ngIf="user.userType === 'PARTNER' || (moduleArray.indexOf(1) !== -1)">
      <a [routerLink]="['/customer']" [routerLinkActive]="['nav-active']"><span><i
            class="bi bi-people-fill"></i></span><ng-container i18n>Clients</ng-container></a>
    </li>
    <li class="nav-item" *ngIf="user.userType === 'PARTNER' || (moduleArray.indexOf(2) !== -1)">
      <a [routerLink]="['/proposal']" [routerLinkActive]="['nav-active']"><span><i
            class="bi bi-clipboard-data-fill"></i></span><ng-container i18n>Gestion des affaires</ng-container></a>
    </li>
    <li class="nav-item" *ngIf="user.userType === 'AGENT'">
      <a [routerLink]="['/agent/commission/list', user.partnerId]"
        [routerLinkActive]="['nav-active']"><span><app-financial-statement-icon width="16px"
            height="auto" /></span><ng-container i18n>Commissions</ng-container></a>
    </li>
    <ng-container *ngIf="user.userType === 'PARTNER'">
      <li class="nav-item">
        <a [routerLink]="['/category']" [routerLinkActive]="['nav-active']"><span><i
              class="bi bi-list-task"></i></span><ng-container i18n>Compagnies et produits</ng-container></a>
      </li>
      <li class="nav-item">
        <a [routerLink]="['/agent-network']" [routerLinkActive]="['nav-active']"><span><app-agent-network-icon
              width="16px" height="auto" /></span><ng-container i18n>Conseillers</ng-container></a>
      </li>
      <li class="nav-item">
        <a [routerLink]="['/role-access']" [routerLinkActive]="['nav-active']"><span><i
              class="bi bi-layers-fill"></i></span><ng-container i18n>Rôles et accès</ng-container></a>
      </li>
    </ng-container>
    <li class="nav-item"
      *ngIf="user.userType === 'PARTNER' || (moduleArray.indexOf(5) !== -1)">
      <a [routerLink]="['/data-analysis-extraction']" [routerLinkActive]="['nav-active']"><span><i
            class="bi bi-layers-fill"></i></span><ng-container i18n>Analyses des chiffres</ng-container></a>
    </li>

    <li class="nav-item" *ngIf="user.userType === 'PARTNER' || (moduleArray.indexOf(3) !== -1)">
      <a [routerLink]="['/financial']" [routerLinkActive]="['nav-active']"><span><app-financial-statement-icon
            width="16px" height="auto" /></span><ng-container i18n>État financier</ng-container></a>
    </li>
  </ul>
  <ul class="profile-nav mt-auto">
    <li class="nav-item" (click)="readNotifications()" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
      aria-controls="offcanvasRight">
      <a><span><span class="bi bi-bell"><sup *ngIf="totalNotification && totalNotification > 0">{{ totalNotification
              }}</sup></span></span><ng-container i18n>Notifications</ng-container></a>
    </li>
    <li class="nav-item">
      <div [ngClass]="{'dropend': !isMobile, 'dropup': isMobile}">
    <li class="nav-item dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
      <a class="d-flex justify-content-between">
        <div class="d-flex align-items-center" id="profileDiv">
          <img width="32px" height="32px" *ngIf="profileData?.profilePic != null" src="{{
            profileData?.profilePic
                  ? profileData?.profilePic
                  : 'assets/images/userimg.png'
              }}" alt="" />
          <div id="initialsDiv" *ngIf="profileData?.profilePic == null">{{getInitials()}}</div>
          <ng-container>
            <div class="d-flex flex-column" id="infoDiv">
              <span>
                {{ profileData?.firstName }} {{ profileData?.lastName }}
              </span>
              <span id="emailSpan">{{profileData?.email}}</span>
            </div>
          </ng-container>
        </div>
        <app-double-chevron-icon heigth="16px" width="16px" />
      </a>
    </li>
    <ul class="dropdown-menu">
      <li>
        <a class="dropdown-item" [routerLink]="['/profile']"><span><app-user-icon height="16px"
              width="auto" /></span><ng-container i18n>Mon compte</ng-container>
        </a>
      </li>
      <li>
        <app-language-switcher></app-language-switcher>
      </li>
      <li>
        <hr class="dropdown-divider">
      </li>
      <li>
        <a class="dropdown-item" href="javascript:void(0)" (click)="SignOut()"><span><app-logout-icon height="16px"
              width="auto" /></span><ng-container i18n>Se déconnecter</ng-container>
        </a>
      </li>
    </ul>
    </div>
    </li>
  </ul>
</aside>

<div class="offcanvas offcanvas-end notificaton-offcanvas" tabindex="-1" id="offcanvasRight"
  aria-labelledby="offcanvasRightLabel">
  <div class="offcanvas-header">
    <h5 id="offcanvasRightLabel" i18n>Notifications</h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close">
    </button>
  </div>
  <div class="offcanvas-body">
    <ul>
      <li *ngFor="let notification of notificationList">
        <div class="d-flex align-items-center">
          <div class="notification-content">
            <p class="notification-title d-block mb-1">
              <strong>{{ notification.title }}</strong> {{ notification.body }}
            </p>
            <small>{{
              notification.createdOn | date: VDATE_TIME_FORMAT
              }}</small>
          </div>
        </div>
      </li>
    </ul>
  </div>
  </div>
</div>