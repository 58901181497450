import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { APP_BASE_HREF } from '@angular/common';
@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.css'],
})
export class LanguageSwitcherComponent {
  countryFlag: any = 'flag03.png';
  public lang: string = '/';
  public selectedCountryCode: string = 'fr';
  public countryWithCodes: any = ['en', 'fr'];
  public customLabels: any = {
    en: 'English',
    fr: 'Français',
  };
  constructor(
    private router: Router,
    private cookieService: CookieService,
    @Inject(APP_BASE_HREF) private baseHref: string
  ) {
    if (this.baseHref === '/') {
      this.cookieService.set('selectedCountryCode', 'fr');
      this.cookieService.set('selectedCountryFlag', this.countryFlag);
      this.selectedCountryCode = 'fr';
    } else {
      let baseHref = this.baseHref.replace(/^\/|\/$/g, '');
      this.cookieService.set('selectedCountryCode', baseHref);
      this.cookieService.set('selectedCountryFlag', 'flag01.png');
      this.selectedCountryCode = baseHref;
    }
    this.countryFlag = `${this.selectedCountryCode}.svg`;
  }

  trimLastSlashFromUrl(baseUrl: any = '') {
    if (baseUrl[baseUrl.length - 1] == '/') {
      alert(baseUrl);
      let trimmedUrl = baseUrl.substring(0, baseUrl.length - 1);
      return trimmedUrl;
    }
  }

  public languageSwitcherAction(value: string = '', flag: any = ''): void {
    if (value == '') {
      return;
    }
    let redirectTo = '';
    if (value != '') {
      if (value === 'fr') {
        redirectTo = (window.location.origin + '/' + this.router.url).replace(
          /([^:]\/)\/+/g,
          '$1'
        );
      } else {
        redirectTo = (
          window.location.origin +
          '/' +
          value +
          this.router.url
        ).replace(/([^:]\/)\/+/g, '$1');
      }
    }
    window.location.href = redirectTo;
  }
}
