import { NgModule } from '@angular/core';
import { AgentNetworkIcon } from './agent-network-icon.component';
import { FinancialStatementIcon } from './financial-statement-icon.component';
import { LetterManagementIcon } from './letter-management-icon.component';
import { ChevronUpIcon } from './chevron-up-icon.component';
import { DoubleChevronIcon } from './double-chevron-icon.component';
import { UserIcon } from './user-icon.component';
import { LogoutIcon } from './logout-icon.component';
import { ChevronLeftIcon } from './chevron-left-icon.component';
import { SearchIcon } from './search-icon.component';
import { CrossIcon } from './cross-icon.component';
import { TrashIcon } from './trash-icon.component';
import { PlusIcon } from './plus-icon.component';
import { UploadCloudIcon } from './upload-cloud-icon.component';
import { FileIcon } from './file-icon.component';
import { CheckCircleIcon } from './check-circle-icon.component';
import { DotsIcon } from './dots-icon.component';
import { DownloadIcon } from './download-icon.component';
import { CopyIcon } from './copy-icon.component';
import { EyeIcon } from './eye-icon.component';
import { PenIcon } from './pen-icon.component';
import { SendIcon } from './send-icon.component';
import { SaveIcon } from './save-icon.component';
import { FileAlternateIcon } from './file-alternate-icon.component';

@NgModule({
  declarations: [
    AgentNetworkIcon,
    FinancialStatementIcon,
    LetterManagementIcon,
    ChevronUpIcon,
    DoubleChevronIcon,
    UserIcon,
    LogoutIcon,
    ChevronLeftIcon,
    SearchIcon,
    CrossIcon,
    TrashIcon,
    PlusIcon,
    UploadCloudIcon,
    FileIcon,
    CheckCircleIcon,
    DotsIcon,
    DownloadIcon,
    CopyIcon,
    EyeIcon,
    PenIcon,
    SendIcon,
    SaveIcon,
    FileAlternateIcon,
    ChevronUpIcon,
  ],
  exports: [
    AgentNetworkIcon,
    FinancialStatementIcon,
    LetterManagementIcon,
    ChevronUpIcon,
    DoubleChevronIcon,
    UserIcon,
    LogoutIcon,
    ChevronLeftIcon,
    SearchIcon,
    CrossIcon,
    TrashIcon,
    PlusIcon,
    UploadCloudIcon,
    FileIcon,
    CheckCircleIcon,
    DotsIcon,
    DownloadIcon,
    CopyIcon,
    EyeIcon,
    PenIcon,
    SendIcon,
    SaveIcon,
    FileAlternateIcon,
    ChevronUpIcon,
  ],
})
export class IconsModule {}
